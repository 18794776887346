import React , { useState , useEffect } from 'react';
import CustomButton from '../../others/button/CustomButton';
import { useDispatch } from 'react-redux';
import { setLoadingInModal, closeModal, changeTypeOfModal } from '../../../redux/actions/notificationActions';
import agent from '../../../api/agent';
import { useToastManager } from '../../../contexts/ToastManagerContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { facebookProvider, googleProvider } from '../../../Firebase';
import { Alert } from 'react-bootstrap';
import FirebaseErrorTranslateCode from '../../../helpers/FirebaseTranslateCode';
import SmallSpinner from '../../others/spinner/SmallSpinner';
import ReauthWithEmailForm from '../reauthWithEmailForm/ReauthWithEmailForm';
import { logOut } from '../../../redux/actions/userActions';
import checkIfCurrentUserExists from '../../../helpers/CheckIfCurrentUserExists';

export const DeleteAccountForm = (props) => {

    const [ loading, setLoading ] = useState(true);
    const [ buttonLoading, setButtonLoading ] = useState(false);
    const [ modalContent , setModalContent ] = useState();
    const [ error, setError ] = useState();
    const [ showModalForReauthWithEmail, setShowModalForReauthWithEmail ] = useState(false);
    
    const { currentUser, reauthWithSocialMedia, refreshJwtToken, deleteCurrentUser } = useAuth()
    const dispatch = useDispatch();
    const { showToast } = useToastManager();
    const history = useHistory();

    const Contents = [
        {
            title: "USUNIĘCIE KONTA - KROK 1",
            content: "UWAGA!\nUsunięcie konta to nieodwracalny krok, w którym zostają usunięte zapisane odpowiedzi do pytań i działu nauki.\nCzy na pewno chcesz usunąć konto?",
            buttonFunction: () => setModalContent(Contents[1])
        },
        {
            title: "USUNIĘCIE KONTA - KROK 2",
            content: "UWAGA!\nZatwierdzasz usunięcie konta!\nTego kroku nie można cofnąć!\nPo zatwierdzeniu zostaną usunięte wszystkie dane należące do konta.\nCzy na pewno chcsz usunąć konto?",
            buttonFunction: () => deleteUserAccount()
        }
    ];

    useEffect(() => {
        setModalContent(Contents[0])
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const deleteUserAccount = async () => {
        setButtonLoading(true);
        try{
            
            await checkIfCurrentUserExists();

            dispatch(setLoadingInModal(true));

            const token = await refreshJwtToken();

            deleteCurrentUser().then( async () => {
                await agent.User.delUser(token).then(() => {
                    dispatch(closeModal());
                    dispatch(logOut());
                    history.push("/logowanie");
                    showToast('custom-success' , 'Konto zostało poprawnie usunięte');
                });
            }).catch(error => {
                const polishError = FirebaseErrorTranslateCode(error.code);
                setError(polishError);
                if(error.code === 'auth/requires-recent-login'){
                    setTimeout(function(){
                        reauthUser();
                    }, 3500);
                }else{
                    setButtonLoading(false);
                }
            });

        }catch(e){
            console.log(e);
            showToast('custom-error' , 'Nie udało się usunąć konta');
            dispatch(closeModal());
        }
    }

    const reauthUser = () => {
        const currentProviderId = currentUser.providerData[0].providerId;
        
        if(currentProviderId === 'password'){
            setError();
            dispatch(changeTypeOfModal('Success'));
            setShowModalForReauthWithEmail(true);
        }else{
            const provider = getProvider(currentProviderId); 
            reauthWithSocialMedia(provider);
        }
        setButtonLoading(false);
    }

    const getProvider = (currentProviderId) => {
        if(currentProviderId === 'google.com'){
            return googleProvider;
        }
        if(currentProviderId === 'facebook.com'){
            return facebookProvider;
        }
    }

    if(loading)
    return(<SmallSpinner/>)
    else
    return (
        <>
            <div className="Modal__header">
                <h5>
                    {modalContent.title}
                </h5>
            </div>
            {   
                error && 
                <Alert 
                    variant="danger" 
                    className="alert-danger-custom UpdateEmailForm__TextField"
                >
                {error}
                </Alert>
            }
            {
                !showModalForReauthWithEmail &&
                <>
                <div className="Modal__body">
                    {modalContent.content}
                </div>
                <div className="Modal__footer">
                    <CustomButton 
                        content={"Nie"} 
                        onClick={() => props.CloseModal()}
                    />
                    <CustomButton 
                        content={"Usuwam konto"} 
                        loading={buttonLoading} 
                        color={"red"}
                        onClick={modalContent.buttonFunction}
                    />
                </div>
                </>
            }
            {
                showModalForReauthWithEmail &&
                <ReauthWithEmailForm 
                    showReauthWithEmailForm={() => setShowModalForReauthWithEmail()}
                    closeModal={() => props.CloseModal()}
                />
            }

        </>
    )
}
