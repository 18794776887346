import React from 'react'
import './FooterSection.css'

const FooterSection = () => {
    return (
        <div className="FooterSection">
            <div className="FooterSection__container">
                <p></p>
                <p>PRAWOJAZDY.PRO od 2018</p>
                <p>Necuit.com</p>
            </div>
        </div>
    )
}

export default FooterSection
