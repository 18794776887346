import React from 'react';
import './AccountPremiumInformation.css';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import CustomButton from '../../others/button/CustomButton';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useToastManager } from '../../../contexts/ToastManagerContext';
import moment from 'moment';

const AccountPremiumInformation = (props) => {

    const history = useHistory();
    const { showToast } = useToastManager();

    const isActivePremium = useSelector((state) => state.user.isActivePremium);
    const premiumActiveTo = useSelector((state) => state.user.premiumActiveTo);

    return(
        <>
            <div className='AccountPremiumInformation__Container'>
                <div className='AccountIcon__Container'>
                    <IoCheckmarkCircleOutline
                        className={"icon--success"}
                    />
                    {
                        premiumActiveTo !== null &&
                        <p><span style={{fontWeight: 500}}> {isActivePremium ? "Data końca premium:  " : "Premium zakończyło się:  "}</span>
                        {moment(new Date(premiumActiveTo)).local().format('DD.MM.YYYY HH:mm')}
                        <br/>Przedłuż swoje premium już teraz jeśli potrzebujesz więcej czasu na naukę.</p>
                    }
                    {
                        premiumActiveTo === null &&
                        <p><span style={{fontWeight: 500}}>Odblokuj konto premium</span>
                        <br/>Kup premium i odblokuj już teraz dostęp do całej aplikacji.</p>
                    }
                </div>
                {
                    !props.isInSettingPage &&
                    <div style={{width: '100%'}}>
                        <CustomButton 
                        onClick={() => showToast('custom-error' , 'Płatności na stronie są wyłączone. Prosimy o kupno w aplikacji mobilnej.')}
                        content={"Kup teraz"}
                        widthInPercent={100}
                        />
                    </div>
                }
        </div>
        {
            props.isInSettingPage &&
            <div style={{width: '100%'}}>
                <CustomButton 
                onClick={() => showToast('custom-error' , 'Płatności na stronie są wyłączone. Prosimy o kupno w aplikacji mobilnej.')}
                content={"Kup teraz"}
                widthInPercent={100}
                />
            </div>
        }
        </>
    )
}

export default AccountPremiumInformation