
const FirebaseErrorTranslateCode = (errorCode)  => {
    switch(errorCode) {
        case 'auth/email-already-in-use':
            return 'Konto o podanym adresie email już istnieje.';
        case 'auth/invalid-email':
            return 'Nie poprawny format adresu email.'
        case 'auth/weak-password':
            return 'Hasło musi mieć minimum 6 znaków.'
        case 'auth/user-disabled':
            return 'Użytkownik jest zablokowany.'
        case 'auth/user-not-found':
            return 'Konto o podanym emailu nie zostało utworzone.'
        case 'auth/wrong-password':
            return 'Nie poprawny e-mail lub hasło.'
        case 'auth/expired-action-code':
            return 'Link stracił ważność.'
        case 'auth/invalid-action-code':
            return 'Link został już wykorzystany lub jest nie poprawny.'
        case 'auth/too-many-requests':
            return 'Przekroczono maksymalną liczbę zapytań.'
        case 'auth/requires-recent-login':
            return 'Przed operacją, należy się ponownie zalogować. Za chwilę zostaniesz przekierowany do ponownego zalogowania.'
        case 'auth/user-mismatch':
            return 'Podane poświadczenia nie odpowiadają poprzednio zalogowanemu użytkownikowi.'
        default:
            return 'Wystąpił błąd';
    }
}

export default FirebaseErrorTranslateCode;