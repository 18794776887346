import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import './MainSection.css'
import mockupImage from '../../../images/image-header.webp'
import { Link } from 'react-router-dom';

const MainSection = () => {
    return (
        <div className="MainSection">
        <Container fluid="xl">
                <div className="header">
                    <Row>
                        <Col xl>
                                <div className="header__column--left">
                                    <div className="header__column-text">
                                        <div className="header__row">
                                            <h1>Najlepsze testy na prawo jazdy 2023</h1>
                                            <p>Profesjonalny portal e-learningowy pozwalający w łatwy sposób przygotować się do egzaminu na prawo jazdy.</p>
                                        </div>
                                        <div className="header__row-button">
                                            <Link to='/rejestracja'>
                                                <button className="header__button--blue">Załóż darmowe konto</button>
                                            </Link>
                                            <Link to='/logowanie'>
                                                <button className="header__button--green">Zaloguj się</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                        </Col>
                        <Col xl>
                                <div className="header__column--right">
                                        <Image 
                                            alt="Aplikacja internetowa prawojazdy.pro"
                                            src={mockupImage} 
                                            fluid />
                                </div>
                        </Col>
                    </Row>
                </div>
        </Container>
        </div>
    )
}

export default MainSection
